<template>
  <div class="Contacts">
    <ContactsHeader />
    <FormSection />
  </div>
</template>
<script>
import ContactsHeader from "@/components/ContactsHeader";
import FormSection from "@/components/FormSection";
export default {
  name: "Contacts",
  components: {
    FormSection,
    ContactsHeader,
  },
  metaInfo: {
    title: 'Our Contacts - Monterizo',
    /*meta: [{
      vmid: 'description',
      name: 'description',
      content:"Here You Can Find How to Contact With Us ✅ 8+ Years Expertise ✅ 300+ Professional Who Work for Result ✅ More Than 500 000 FTD Per Year",
    }],*/
  }
}
</script>

<style lang="scss">
.Contacts {
  padding: 3.9vw 0 0;

  .mobile &,
  .landscape &,
  .portrait & {
    padding: 13.2vw 0 0;
  }
}
</style>