<template>
  <section class="ContactsHeader">
    <img :src="require(`@/assets/images/${switchBgImage}`)" alt="bg" class="ContactsHeader-Bg">
    <h1 class="ContactsHeader-Title">
      <img src="@/assets/images/logo-white.png" alt="">
      Contacts
    </h1>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ContactsHeader",
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
    }),
    switchBgImage() {
      return this.isMobile ? 'contacts-header-bg.png' : 'contacts-header-bg.png'
    }
  }
}
</script>

<style lang="scss">
.ContactsHeader {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
  height: 44.271vw;
  background-color: var(--color-body1);

  &-Bg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-Title {
    position: absolute;
    z-index: 2;
    top: 22%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, 0);
    font-weight: 900;
    font-size: 11.927vw;
    line-height: .8;
    text-align: right;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: var(--color-text-main1);

    .mobile &,
    .landscape &,
    .portrait & {
      left: 50%;
      font-size: 15.7vw;
    }

    img {
      width: 38.11%;
      margin-bottom: 2.1vw;
    }
  }

}
</style>